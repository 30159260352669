<template>
   <div>
	  <section id="reports">
		 <b-row>
			<b-col lg="3">
			   <b-card
				   bg-variant="primary"
				   border-variant="primary"
				   class="shadow-none"
				   text-variant="white"
			   >
				  <b-card-header class="pb-0">
					 <h5 class="mb-2 text-white">Επίλεξε αναφορά</h5>
				  </b-card-header>

				  <b-form-group>
					 <v-select
						 :options="option"
						 class="primary"
						 label="title"
					 />
				  </b-form-group>
				  <b-card-text>
					 This is some text within a card body. Jelly lemon drops tiramisu
					 chocolate cake cotton candy soufflé oat cake sweet roll. Sugar
					 plum marzipan dragée topping cheesecake chocolate bar. Danish
					 muffin icing donut.
				  </b-card-text>
			   </b-card>
			</b-col>

			<b-col lg="9">
			   <b-card-actions action-collapse
							   title="Φίλτρα">
				  <!-- Filters -->
				  <b-row>
					 <b-col lg="3">
						<b-form-group :label="$t('generic.client')">
						   <v-select
							   v-model="selected"
							   :options="clients"
						   />
						</b-form-group>
					 </b-col>
				  </b-row>
				  <!-- END: Filters -->

				  <b-button block
							class="mt-2"
							variant="outline-primary">
					 <span>{{ $t("generic.view-results") }}</span>
				  </b-button>
			   </b-card-actions>

			   <!--Πίνακας αποτελεσμάτων -->
			   <b-card no-body>
				  <b-row>
					 <b-col class="text-right">
						<b-button-group class="m-2"
										horizontal>
						   <b-button
							   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							   class="btn-icon"
							   variant="outline-primary"
						   >
							  <feather-icon icon="DownloadIcon" />
						   </b-button>
						   <b-button
							   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							   class="btn-icon"
							   variant="outline-primary"
						   >
							  <feather-icon icon="MailIcon" />
						   </b-button>
						</b-button-group>
					 </b-col>
				  </b-row>
				  <b-table
					  :items="items"
					  responsive="sm"
				  />
			   </b-card>
			   <!--Πίνακας αποτελεσμάτων -->
			</b-col>
		 </b-row>
	  </section>
   </div>
</template>

<script>
import {BButton, BButtonGroup, BButtonToolbar, BCard, BCardText, BCol, BFormGroup, BRow, BTable,} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardText,
	  BFormGroup,
	  vSelect,
	  BButton,
	  BButtonToolbar,
	  BButtonGroup,
	  BCardActions,
	  BTable
   },
   data() {
	  return {
		 option: [
			{title: "Καρτέλα πελάτη"},
			{title: "Υπόλοιπα πελατών"},
			{title: "Λίστα πελατών"},
			{title: "Λίστα έργων"},
			{title: "Λίστα εργασιών"},
			{title: "Λίστα εξόδων"},
			{title: "Έσοδα ανά υπηρεσία"},
		 ],
		 clients: [
			{title: "Κώστας Μιχάλαρος"},
			{title: "Πελάτης 2"},
			{title: "Πελάτης 3"},
		 ],
		 items: [
			{
			   age: 40, first_name: 'Dickerson', last_name: 'Macdonald', Occupation: 'Job',
			},
			{
			   age: 21, first_name: 'Larsen', last_name: 'Shaw', Occupation: 'Job',
			},
			{
			   age: 89, first_name: 'Geneva', last_name: 'Wilson', Occupation: 'Bussiness',
			},
			{
			   age: 38, first_name: 'Jami', last_name: 'Carney', Occupation: 'Bussiness',
			},
			{
			   age: 40, first_name: 'James', last_name: 'Thomson', Occupation: 'Job',
			},
		 ],
	  };
   },
   created() {
	  caldoSettings.removeBreadcrumb();
   }
};
</script>


<style scoped>
.primary .vs__dropdown-toggle {
   background: #fff !important;
}

</style>